import React from "react";

import image from "../../images/ticketino.png";
import "../../index.css";

const PageNotFound = () => {
  return (
    <div className="page-container">
      <div className="logo-div">
        <img className="logo" src={image} alt="logo"></img>
      </div>
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className="text-center">
          <p className="text text-dark fw-light fs-4 mb-0">
            Page Not Found
            <span className="text-not-found ms-2">404</span>
          </p>
          <p className="text text-dark fw-light fs-6">
            the current route doesn't exist
            <span className="text-not-found">.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
