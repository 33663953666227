import React from "react";

import iconFB from "../../images/social_media/facebook.png";
import iconTW from "../../images/social_media/twitter.png";
import iconIG from "../../images/social_media/instagram.png";
import iconLI from "../../images/social_media/linkedin.png";
import iconXI from "../../images/social_media/xing.png";
import iconYT from "../../images/social_media/youtube.png";

const Footer = (props) => {
    return (
        <div className="footer mt-4">
            <div className="container wrapper">
                <div className="row mt-3">
                    <div className="col-md-2 col-6">
                        <div className="footer-list mt-2">
                            <div>Dübendorf</div>
                            <div>T +41 58 765 11 11</div>
                            <div>F +41 58 765 11 22</div>
                            <div>
                                <a href="https://www.empa.ch/documents/20195/56305/directions_duebendorf_en.pdf/8a6de136-6c30-4f00-a0fa-6c906182e9e5">
                                    {props.resources?.FooterDirectionPdf}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-6">
                        <div className="footer-list mt-2">
                            <div>St. Gallen</div>
                            <div>T +41 58 765 74 74</div>
                            <div>F +41 58 765 74 99</div>
                            <div>
                                <a href="https://www.empa.ch/documents/20195/56305/directions_stgallen_en.pdf/fafe2d62-835a-425b-8312-12870675a874">
                                    {props.resources?.FooterDirectionPdf}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-6">
                        <div className="footer-list mt-2">
                            <div>Thun</div>
                            <div>T +41 58 765 11 33</div>
                            <div>F +41 58 765 69 90</div>
                            <div>
                                <a href="https://www.empa.ch/documents/20195/56305/directions_thun_en.pdf/a42f48c4-203d-4293-b832-4cfa1e6b93c8">
                                    {props.resources?.FooterDirectionPdf}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-6">
                        <div className="footer-list mt-2">
                            <div><a href="https://www.empa.ch/web/empa/impressum">Impressum</a></div>
                            <div><a href="https://www.empa.ch/web/empa/disclaimer">Disclaimer</a></div>
                            <div><a href="javascript:window.print()">Print</a></div>
                            <div><a href="https://www.empa.ch/web/empa/sitemap">Sitemap</a></div>
                        </div>
                    </div>
                    <div className="col-md-2 col-6">
                        <div className="footer-list mt-3">
                            <div>
                                <a href="http://www.facebook.com/pages/Empa-Swiss-Federal-Laboratories-for-Materials-Science-and-Technology/227604993916414">
                                <img alt="icon" className="me-2" src={iconFB} /></a>

                                <a href="http://twitter.com/Empa_CH">
                                <img alt="icon" className="me-2" src={iconTW} /></a>

                                <a href="https://www.instagram.com/empa_materials_science/">
                                <img alt="icon" className="me-2" src={iconIG} /></a>
                            </div>              
                            <div className="mt-2">               
                                <a href="http://www.linkedin.com/company/empa">
                                <img alt="icon" className="me-2" src={iconLI} /></a>

                                <a href="http://www.xing.com/companies/empa-swissfederallaboratoriesformaterialsscienceandtechnology">
                                <img alt="icon" className="me-2" src={iconXI} /></a>

                                <a href="http://www.youtube.com/user/EmpaChannel">
                                <img alt="icon" className="me-2" src={iconYT} /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-6">
                        <div className="footer-list mt-2">
                            <div><a href="https://www.empa.ch/web/s604/newsletter"><strong>NEWSLETTER</strong></a></div>
                            <div>{props.resources?.FooterSubscribe}</div>
                            <div><a href="https://www.empa.ch/web/s604/newsletter">{props.resources?.FooterSubscribeNewsletter}</a></div>
                            <div><a href="https://www.empa.ch/web/s604/privacy">{props.resources?.FooterDataProtection}</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
