import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";
import { FaChevronRight } from "react-icons/fa";

import axios from "axios";

import "../css/style.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";

import logo from "../images/logo.jpg"

const Home = () => {
    const [orderId, setOrderId] = useState();
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState([]);
    const [eventStats, setEventStats] = useState([]);
    const [eventsInfo, setEventsInfo] = useState([]);
    const [eventsLocation, setEventsLocation] = useState([]);
    const [eventAvailability, setEventAvailability] = useState([]);
    const [ticketCount, setTicketCount] = useState([]);

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching resources
    const { language, eventGroupType } = useParams();

    let languageId = 0;

    if (language === "de" || language === "DE") {
        languageId = 1;
    } else if (language === "en" || language === "EN") {
        languageId = 3;
    } else {
        languageId = 1;
    }

    useEffect(() => {
        loadToken();
    }, []);

    useEffect(() => {
        requestResources();
    }, [language]);

    const loadToken = async () => {
        await axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = async () => {
        await axios.get(`form/formsettings`).then((res) => {

            let eventGroupIds = [];

            if (eventGroupType === 'veranstaltungen' || eventGroupType === undefined) {
                eventGroupIds.push(...[res.data.eventGroupId1, res.data.eventGroupId2, res.data.eventGroupId3,
                res.data.eventGroupId4, res.data.eventGroupId5, res.data.eventGroupId6, res.data.eventGroupId7,
                res.data.eventGroupId8, res.data.eventGroupId9, res.data.eventGroupId10, res.data.eventGroupId11,
                res.data.eventGroupId12]);
            }

            if (eventGroupType === 'fuehrungen' || eventGroupType === undefined) {
                eventGroupIds.push(...[res.data.eventGroupGuidedToursId1, res.data.eventGroupGuidedToursId2,
                res.data.eventGroupGuidedToursId3, res.data.eventGroupGuidedToursId4, res.data.eventGroupGuidedToursId5,
                res.data.eventGroupGuidedToursId6, res.data.eventGroupGuidedToursId7, res.data.eventGroupGuidedToursId8,
                res.data.eventGroupGuidedToursId9, res.data.eventGroupGuidedToursId10, res.data.eventGroupGuidedToursId11,
                res.data.eventGroupGuidedToursId12]);
            }

            if (eventGroupType === 'openlabday' || eventGroupType === undefined) {
                eventGroupIds.push(...[res.data.eventGroupDayOfOpenDoorsId1, res.data.eventGroupDayOfOpenDoorsId2,
                res.data.eventGroupDayOfOpenDoorsId3, res.data.eventGroupDayOfOpenDoorsId4, res.data.eventGroupDayOfOpenDoorsId5,
                res.data.eventGroupDayOfOpenDoorsId6, res.data.eventGroupDayOfOpenDoorsId7, res.data.eventGroupDayOfOpenDoorsId8,
                res.data.eventGroupDayOfOpenDoorsId9, res.data.eventGroupDayOfOpenDoorsId10, res.data.eventGroupDayOfOpenDoorsId11,
                res.data.eventGroupDayOfOpenDoorsId12]);
            }

            if (eventGroupType !== 'fuehrungen' && eventGroupType !== 'veranstaltungen' && eventGroupType !== 'openlabday' &&
                eventGroupType !== undefined) {
                navigate(`/${language}/PageNotFound`)
            }

            startOrder(res.data.posId, eventGroupIds);
        });
    };

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId, eventGroupIds) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("OrderId", res.data.id);

                setOrderId(res.data.id);
                setLoading(true);
                requestEventsInEventGroup(eventGroupIds, res.data.id);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestEventsInEventGroup = async (eventGroupIds, orderId) => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-US', {
            year: 'numeric', month: '2-digit', day: '2-digit'
        });


        const allEvents = [];

        // Create an array of promises for each request
        const requestPromises = eventGroupIds.map(async (eventGroupId) => {
            try {
                const res = await axios.get(
                    `${baseUrl}/EventGroup/Events?eventGroupId=${eventGroupId}&languageCode=de&dateFrom=${formattedDate}`
                );

                const eventsInGroup = res.data.events;
                allEvents.push(...eventsInGroup);
            } catch (error) {
                console.error(error.response.data);
            }
        });

        // Wait for all the requests to complete
        await Promise.all(requestPromises);

        // Update the 'events' state with the collected events
        setEvents(allEvents);

        // Process events here, after all requests are completed:
        allEvents.forEach((event) => requestEvent(event.id));
        allEvents.forEach((event) => requestEventsInfo(event.id));
        allEvents.forEach((event) => requestTicketCount(event.id));
        allEvents.forEach((event) => requestTicketType(event.id, orderId));

        if (allEvents.length === 0) setLoading(false);
    };

    const requestEvent = async (id) => {
        await axios
            .get(`${baseUrl}/Event/${id}`)
            .then((res) => {
                setEventStats((current) => [
                    ...current,
                    res.data
                ]);

                requestEventsLocationInfo(id, res.data.locationId);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketCount = (id) => {
        axios
            .get(`${baseUrl}/Event/${id}/TicketsSold`)
            .then((res) => {
                setTicketCount((current) => [
                    ...current,
                    {
                        eventId: id,
                        ticketsSold: res.data
                    },
                ]);
            })
            .catch((error) => console.error(error.response.data));
    }

    const requestEventsInfo = async (id) => {
        await axios
            .get(`${baseUrl}/Event/${id}/Infos`)
            .then((res) => {
                let info = res.data.eventInfos.findIndex((tti) => tti.languageId === languageId);

                let eventInfo = {};
                let defaultEventInfo = res.data.eventInfos.find((tti) => tti.languageId === 0);

                if (info > 0) {
                    eventInfo = res.data.eventInfos.find((tti) => tti.languageId === languageId);
                } else {
                    eventInfo = defaultEventInfo;
                }

                if (!(eventInfo.flyerImagePath.length > 0)) {

                    if (defaultEventInfo.flyerImagePath.length > 0) {
                        eventInfo.flyerImagePath = defaultEventInfo.flyerImagePath;
                    } else {
                        eventInfo.flyerImagePath = logo
                    }
                }

                setEventsInfo((current) => [
                    ...current,
                    eventInfo,
                ]);
            })
            .catch((error) => console.error(error.response.data));

        setTimeout(() => setLoading(false), 500);
    };

    const requestEventsLocationInfo = async (eventId, locationId) => {
        await axios
            .get(`${baseUrl}/Location/${locationId}/Infos`)
            .then((res) => {
                let locationObj = {
                    eventId: eventId,
                    street: res.data.locationInfos[0]?.street,
                    city: res.data.locationInfos[0]?.city
                }

                setEventsLocation((current) => [
                    ...current,
                    locationObj,
                ]);
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketType = (eventId, orderId) => {
        axios
            .get(`${baseUrl}/Event/${eventId}/TicketTypes`)
            .then((res) => {
                let ticketTypesArray = [];

                ticketTypesArray = res.data.ticketTypes;

                ticketTypesArray.map((tt) =>
                    requestTicketTypeNumberOfAvailableTickets(tt.id, eventId, orderId)
                );
            })
            .catch((error) => console.error(error.response.data));
    };

    const requestTicketTypeNumberOfAvailableTickets = (ticketTypeId, eventId, orderId) => {
        axios
            .get(`${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}`)
            .then((res) => {
                setEventAvailability((current) => [
                    ...current,
                    {
                        eventId: eventId,
                        status: res.data.status,
                        availableTickets: res.data.availableTickets,
                    },
                ]);
            })
            .catch((error) => console.error(error.response.data));
    };

    const ticketAvailabilityIcon = (eventStatsForEvent, event, eventPreSaleEnd, currentTime) => {

        return (
            <div>
                {eventStatsForEvent && (
                    <div>
                        {event?.contigent === 0 ? (
                            // If event is sold out
                            <div className="ticket-inline ticket-availability-soldout"></div>
                        ) : (
                            (() => {
                                if (eventPreSaleEnd < currentTime) {
                                    if (eventStatsForEvent?.postSaleCloseStatus !== 2) {
                                        // If presale is over
                                        return <div className="ticket-inline ticket-availability-additionalinfo"></div>;
                                    } else {
                                        // If presale is over, and tickets are available at the box office
                                        return <div className="ticket-inline ticket-availability-tickets-available-at-box-office"></div>;
                                    }
                                } else {
                                    // event is available
                                    return <div className="ticket-inline ticket-availability-available"></div>;
                                }
                            })()
                        )}
                    </div>
                )}

            </div>
        )
    }

    const mapEvents = () => {
        let eventArray = [];

        eventArray = events;

        eventArray.map((event, index) => {
            eventArray[index].availableTickets = eventAvailability.find((ea) => ea.eventId === eventArray[index].id) && eventAvailability.find((ea) => ea.eventId === eventArray[index].id).availableTickets;
            eventArray[index].status = eventAvailability.find((ea) => ea.eventId === eventArray[index].id) && eventAvailability.find((ea) => ea.eventId === eventArray[index].id).status;
            eventArray[index].contigent = (eventStats.find((es) => es.id === eventArray[index].id) && eventStats.find((es) => es.id === eventArray[index].id).maxTickets) - (ticketCount.find((tc) => tc.eventId === eventArray[index].id) && ticketCount.find((tc) => tc.eventId === eventArray[index].id).ticketsSold)
            eventArray[index].group = false
        })

        eventArray.sort((a, b) =>
        (
            eventsInfo.find(info => info.eventId === a.id)?.name.localeCompare((eventsInfo.find(info => info.eventId === b.id)?.name)) &&
            eventArray.find(event => event.id === a.id).start.localeCompare((eventArray.find(event => event.id === b.id).start))
        ));

        return (
            eventArray.length > 0 &&
            eventArray
                .filter((event) => eventStats.find((es) => es?.id === event?.id)?.status === 1) // Filter active events
                .map((event, index) => {
                    const eventStatsForEvent = eventStats.find((es) => es?.id === event?.id);
                    const eventPreSaleEnd = new Date(eventStatsForEvent.preSaleEnd)
                    const currentTime = new Date();

                    return (
                        <div className="row mb-5" key={index}>
                            <div className="col-md-12">
                                <hr className="hr-eventlist" />
                            </div>
                            <div className="col-md-1 col-5 mb-2">
                                <img
                                    alt="flyer"
                                    className="img-fluid"
                                    src={
                                        eventsInfo.find((ei) => ei?.eventId === event?.id) &&
                                        eventsInfo.find((ei) => ei?.eventId === event?.id).flyerImagePath
                                    }
                                ></img>
                            </div>
                            <div className="offset-5 col-2 mt-4 mobile-only">
                                {ticketAvailabilityIcon(eventStatsForEvent, event, eventPreSaleEnd, currentTime)}
                            </div>
                            <div className="col-md-3 col-12 mt-2 mb-2">
                                <p className="fs-6 fw-bold mb-0">
                                    {eventsInfo.find((ei) => ei?.eventId === event?.id) &&
                                        eventsInfo.find((ei) => ei?.eventId === event?.id).name}
                                </p>
                            </div>
                            <div className="col-md-3 mt-2 mb-2">
                                <p className="mb-0 fw-bold">
                                    {eventStats.find((es) => es?.id === event?.id) &&
                                        new Date(eventStats.find((es) => es?.id === event?.id).start).toLocaleDateString('de-CH')} -
                                    {eventStats.find((es) => es?.id === event?.id) &&
                                        new Date(eventStats.find((es) => es?.id === event?.id).end).toLocaleDateString('de-CH')}
                                </p>
                                <p className="mb-0">
                                    {resources?.HomeOpenDoor} {eventStats.find((es) => es?.id === event?.id) &&
                                        new Date(eventStats.find((es) => es?.id === event?.id).openDoor).toLocaleString(language, { timeStyle: `short` })} {resources?.Time}
                                </p>
                                <p className="mb-0">
                                    {resources?.HomeStart} {eventStats.find((es) => es?.id === event?.id) &&
                                        new Date(eventStats.find((es) => es?.id === event?.id).start).toLocaleString(language, { timeStyle: `short` })} {resources?.Time}
                                </p>
                            </div>
                            <div className="col-md-2 mt-2 mb-2">
                                <p className="fs-6 mb-2">
                                    {eventsLocation.find((el) => el.eventId === event.id) &&
                                        ((eventsLocation.find((el) => el.eventId === event.id).street ? eventsLocation.find((el) => el.eventId === event.id).street : "") + " " + eventsLocation.find((el) => el.eventId === event.id).city)}
                                </p>
                            </div>

                            <div className="col-md-1 mb-2 availability-ticket-div hidden-mobile">
                                {ticketAvailabilityIcon(eventStatsForEvent, event, eventPreSaleEnd, currentTime)}
                            </div>

                            <div className="col-md-2 mt-3 mb-2 text-md-end">
                                {event?.contigent !== 0 && eventPreSaleEnd > currentTime ? (
                                    <a
                                        className="btn-custom"
                                        href={`https://www.ticketino.com/${language}/Event/detail/${event.id}`}
                                    >
                                        {resources?.HomeButtonRegistration}
                                    </a>
                                ) : (
                                    <a className="btn-custom disabled">{resources?.HomeButtonRegistration}</a>
                                )}
                            </div>
                        </div>
                    );
                })
        );

    };

    return (
        <div className="page-container pt-0">
            <Header resources={resources} language={language} />
            {!loading ?
                <>
                    <div className="container wrapper">


                        <div className="row">
                            <div className="col-md-12 navigation-links">
                                <span><a href="#">Home</a></span>
                                <span> <BiSolidChevronRight className={eventGroupType === undefined && 'red'} /><a className={eventGroupType === undefined ? 'red ms-1' : 'ms-1'} href="#">{resources?.HomeOverview}</a></span>
                                {eventGroupType === 'veranstaltungen' && <span> <BiSolidChevronRight className="red" /><a className="ms-1 red" href="#">{resources?.HeaderEvents}</a></span>}
                                {eventGroupType === 'fuehrungen' && <span> <BiSolidChevronRight className="red" /><a className="ms-1 red" href="#">{resources?.HeaderGuidedTours}</a></span>}
                                {/*{eventGroupType === 'openlabday' && <span> <BiSolidChevronRight className="red" /><a className="ms-1 red" href="#">{resources?.HeaderDayOfOpenDoors}</a></span>}*/}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-3 hidden-mobile">
                                <div><FaChevronRight className={eventGroupType === undefined && 'red'} /> <a className={eventGroupType === undefined && 'red'} href={`/${language}/home`}>{resources?.HomeOverview}</a></div>
                                <div className="ms-3"><FaChevronRight className={eventGroupType === 'veranstaltungen' && 'red'} /> <a className={eventGroupType === 'veranstaltungen' && 'red'} href={`/${language}/home/veranstaltungen`}>{resources?.HeaderEvents}</a></div>
                                <div className="ms-3"><FaChevronRight className={eventGroupType === 'fuehrungen' && 'red'} /> <a className={eventGroupType === 'fuehrungen' && 'red'} href={`/${language}/home/fuehrungen`}>{resources?.HeaderGuidedTours}</a></div>
                                {/*<div className="ms-3"><FaChevronRight className={eventGroupType === 'openlabday' && 'red'} /> <a className={eventGroupType === 'openlabday' && 'red'} href={`/${language}/home/openlabday`}>{resources?.HeaderDayOfOpenDoors}</a></div>*/}
                            </div>

                            <div className="col-md-6">
                                {eventGroupType === 'fuehrungen' ? (
                                    <>
                                        <h2>{resources?.HomeTitleGuidedTours}</h2>
                                        <h4>{resources?.HomeDescriptionGuidedTours}</h4>
                                    </>
                                ) : eventGroupType === 'openlabday' ? (
                                    <>
                                        <h2>{resources?.HomeTitleDayOfOpenDoors}</h2>
                                        <h4>{resources?.HomeDescriptionDayOfOpenDoors}</h4>
                                    </>
                                ) : (
                                    <>
                                        <h2>{resources?.HomeTitleOverview}</h2>
                                        <h4>{resources?.HomeDescriptionOverview}</h4>
                                    </>
                                    )}
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-md-12 fw-bold">
                                {resources?.HomeSearchResults}
                            </div>
                        </div>

                        <div className="tickets-availability-main-div mt-2 desktop-only">
                            <div className="tickets-availability-div p-3">
                                <div className="ticket-inline ticket-availability-available"></div>
                                <span className="ticket-description">{resources?.HomeTicketsAvailable}</span>
                            </div>
                            <div className="tickets-availability-div p-3">
                                <div className="ticket-inline ticket-availability-tickets-available-at-box-office"></div>
                                <span className="ticket-description">{resources?.HomeTicketsAtBoxOffice}</span>
                            </div>
                            <div className="tickets-availability-div p-3">
                                <div className="ticket-inline ticket-availability-additionalinfo"></div>
                                <span className="ticket-description">{resources?.HomeTicketsPresaleIsOver}</span>
                            </div>
                            <div className="tickets-availability-div p-3">
                                <div className="ticket-inline ticket-availability-soldout"></div>
                                <span className="ticket-description">{resources?.HomeTicketsSoldOut}</span>
                            </div>
                        </div>

                        <div className="tickets-availability-main-div mt-2 pt-2 pb-2 mobile-tablet-only">
                            <div className="ps-3 pe-3 pt-1 pb-1">
                                <div className="ticket-inline ticket-availability-available"></div>
                                <span className="ticket-description">{resources?.HomeTicketsAvailable}</span>
                            </div>
                            <div className="p-3 pe-3 pt-1 pb-1">
                                <div className="ticket-inline ticket-availability-tickets-available-at-box-office"></div>
                                <span className="ticket-description">{resources?.HomeTicketsAtBoxOffice}</span>
                            </div>
                            <div className="p-3 pe-3 pt-1 pb-1">
                                <div className="ticket-inline ticket-availability-additionalinfo"></div>
                                <span className="ticket-description">{resources?.HomeTicketsPresaleIsOver}</span>
                            </div>
                            <div className="p-3 pe-3 pt-1 pb-1">
                                <div className="ticket-inline ticket-availability-soldout"></div>
                                <span className="ticket-description">{resources?.HomeTicketsSoldOut}</span>
                            </div>
                        </div>

                        {events.length === 0 &&

                            <div className="mt-5">
                                {eventGroupType === "veranstaltungen" && 
                                    <span>{resources?.HomeNoEventsVeranstaltungen}</span>
                                }
                                {eventGroupType === "fuehrungen" &&
                                    <span>{resources?.HomeNoEventsGuidedTours}</span>
                                }
                                {eventGroupType === "openlabday" &&
                                    <span>{resources?.HomeNoEventsDayOfOpenDoors}</span>
                                }
                                
                            </div>
                        }

                        <div className="mt-5">{mapEvents()}</div>

                    </div>
                    <Footer resources={resources} />
                </>
                :
                <Loading
                    alignment="center"
                    color="#f6b326"
                    bgColor="transparent"
                    position="fixed"
                    top="70%"
                    left="50%"
                />}
        </div>
    );
};

export default Home;
