import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// SwissClassicalWorld
import Home from "./views/Home";

// Fallback page
import PageNotFound from "./views/components/PageNotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/de/home" replace />} />
        <Route path="*" element={<PageNotFound />} />

        <Route path="/:language/home" element={<Home />} />
        <Route path="/:language/home/:eventGroupType" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
